<template>
  <v-app>
    <v-container>
      <!--      <div>Result: {{ filters.qr_code }}</div>-->
      <CodeScanner name="qr_code" @onScanned="onQRScanned" />
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "QrScan",
  components: {
    CodeScanner: () => import("@/components/common/CodeScanner"),
  },
  data: () => ({
    filters: {},
  }),
  created() {},
  methods: {
    onQRScanned(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
  },
};
</script>

<style scoped></style>
